<template>
  <div class="">
    <delete-program
      v-if="showDeleteProgramItem"
      :showModal="showDeleteProgramItem"
      :programItem="selectedProgramItem"
      :isLocked="isLocked"
      :isEmployee="isEmployee"
      :onClickCancel="hideModal"
    />

    <ui-section-header>
      <template v-slot:title>{{
        $t('Components.Reservation.ReservationProgram.Main_Title')
      }}</template>
    </ui-section-header>

    <div class="columns">
      <div
        key="programmWrapper"
        class="column has-margin-bottom"
        v-if="hasLoaded"
      >
        <table class="table is-fullwidth is-striped">
          <tbody>
            <tr v-for="(item, index) in sortedProgramItems" :key="index">
              <td>
                <div class="control">
                  <div class="select">
                    <select
                      v-model="item.StartMinutes"
                      @change="saveReservationProgram"
                    >
                      <option
                        v-for="(option, index) in timeMinutesOptions"
                        :key="index"
                        :value="option"
                        >{{ option | minutesToTime }}</option
                      >
                    </select>
                  </div>
                </div>
              </td>
              <td>
                <div class="control control-description">
                  <input
                    class="input"
                    maxlength="200"
                    v-model.lazy="item.Description"
                  />
                </div>
              </td>
              <td width="75" class="has-text-right">
                <a
                  class="has-text-danger"
                  @click="setShowDeleteProgramItem(item)"
                >
                  <span class="icon is-medium">
                    <font-awesome-icon :icon="['fas', 'trash-alt']" />
                  </span>
                </a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div class="select">
                  <select v-model="programStartMinutes">
                    <option
                      v-for="(option, index) in timeMinutesOptions"
                      :key="index"
                      :value="option"
                      >{{ option | minutesToTime }}</option
                    >
                  </select>
                </div>
              </td>
              <td>
                <input
                  class="input"
                  maxlength="200"
                  v-on:keyup.13="addProgramItem"
                  v-model="programDescription"
                />
              </td>
              <td>
                <button class="button is-success" @click="addProgramItem">
                  <span class="icon is-small">
                    <font-awesome-icon :icon="['fas', 'plus']" />
                  </span>
                  <span>{{
                    $t('Components.Reservation.ReservationProgram.Icon_Add')
                  }}</span>
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div class="column">
        <message
          v-if="timeSelectedOptions.length === 0"
          :message="$t('Components.Reservation.ReservationProgram.Text_NoOptionsSelectable')"
        />

        <div
          class="field"
          v-for="(option, index) in timeSelectedOptions"
          :key="index"
        >
          <div class="columns">
            <span class="column">
              <select
                v-model="option.SelectedTime"
                class="select"
                @change="optionTimeChanged(option)"
              >
                <option
                  v-for="(option, index) in timeMinutesOptions"
                  :key="index"
                  :value="option"
                  >{{ option | minutesToTime }}</option
                >
              </select>
            </span>
            <span class="column is-three-quarters">{{ option.Name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import reservationProvider from '@/providers/reservation'

import Message from '@/components/UI/Message'
import ModalDeleteProgramItem from '@/components/Reservations/ModalDeleteProgramItem'

export default {
  components: {
    message: Message,
    'delete-program': ModalDeleteProgramItem,
  },

  props: {
    programItems: {
      type: Array,
      default: function() {
        return []
      },
    },
  },

  data() {
    return {
      hasLoaded: false,
      mProgramItems: this.programItems,
      programDescription: '',
      programStartMinutes: 0,
      timeMinutesOptions: this.buildTimeMinutesOptions(0, 1440, 5),
      selectedProgramItem: null,
      showDeleteProgramItem: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    resStart() {
      let self = this
      let minStartMinutes = 0

      if (self.reservation && self.reservation.Spaces.length > 0) {
        let lowest = Number.POSITIVE_INFINITY
        let tmp
        for (let i = self.reservation.Spaces.length - 1; i >= 0; i--) {
          tmp = self.reservation.Spaces[i].StartMinutes
          if (tmp < lowest) {
            lowest = tmp
          }
        }
        minStartMinutes = lowest
      }
      return minStartMinutes
    },

    programMinMinutes() {
      let minMinutes = 1440

      if (this.mProgramItems.length > 0) {
        for (let i = 0; i < this.mProgramItems.length; i++) {
          let programItem = this.mProgramItems[i]
          if (programItem.StartMinutes < minMinutes) {
            minMinutes = programItem.StartMinutes
          }
        }
      }

      return minMinutes
    },

    programMaxMinutes() {
      let maxMinutes = 0

      if (this.mProgramItems.length > 0) {
        for (let i = 0; i < this.mProgramItems.length; i++) {
          let programItem = this.mProgramItems[i]
          if (programItem.StartMinutes > maxMinutes) {
            maxMinutes = programItem.StartMinutes
          }
        }
      }

      return maxMinutes
    },

    sortedProgramItems() {
      return this.mProgramItems.slice().sort((a, b) => {
        // Sort by votes
        // If the first item has a higher number, move it down
        // If the first item has a lower number, move it up
        if (a.StartMinutes > b.StartMinutes) return 1
        if (a.StartMinutes < b.StartMinutes) return -1

        // If the votes number is the same between both items, sort alphabetically
        // If the first item comes first in the alphabet, move it up
        // Otherwise move it down
        if (a.Description.toLowerCase() > b.Description.toLowerCase()) return 1
        if (a.Description.toLowerCase() < b.Description.toLowerCase()) return -1
      })
    },

    timeSelectedOptions() {
      return this.reservation.Options.filter(
        (o) => o.TimeSelectable > 0 && o.SelectedTime > -1
      )
    },
  },

  created() {
    this.getReservationProgram()
    this.programStartMinutes = this.resStart
  },

  methods: {
    getReservationProgram() {
      let self = this
      reservationProvider.methods
        .getReservationProgram(this.reservation.Id)
        .then((response) => {
          if (response.status === 200) {
            self.mProgramItems = response.data
            if (self.mProgramItems.length > 0) {
              self.programStartMinutes =
                self.programMaxMinutes + 60 < 1380
                  ? self.programMaxMinutes + 60
                  : 1380
            }
          }
        })
        .finally(() => {
          self.hasLoaded = true
        })
    },

    addProgramItem() {
      this.mProgramItems.push({
        Id: 0,
        ReservationId: this.reservation.Id,
        StartMinutes: this.programStartMinutes,
        Description: this.programDescription,
      })
      this.programStartMinutes =
        this.programMaxMinutes + 60 < 1380 ? this.programMaxMinutes + 60 : 1380
      this.programDescription = ''

      this.saveReservationProgram()
    },

    buildTimeMinutesOptions(open, close, minutesSteps) {
      let output = []
      let oneMinute = 1

      for (let i = open; i < close; i = i + minutesSteps) {
        output.push(i)
      }
      return output
    },

    deleteProgramItem(index) {
      this.mProgramItems = this.sortedProgramItems
      this.mProgramItems.splice(index, 1)

      this.saveReservationProgram()
    },

    saveReservationProgram() {
      reservationProvider.methods
        .saveReservationProgram(this.reservation.Id, this.mProgramItems)
        .then((response) => {
          // if (response.status === 200) {
          // }
        })
    },

    optionTimeChanged(option) {
      EventBus.$emit('optionUpdated', option)
    },

    setShowDeleteProgramItem(item) {
      this.showDeleteProgramItem = true

      this.selectedProgramItem = item
    },

    hideModal() {
      this.showDeleteProgramItem = false

      this.selectedProgramItem = null
    },
  },
}
</script>
